import React from "react";
import { Formik } from "formik";
import * as styles from "./subscribe-form.module.scss";

const SubscribeForm = () => {
    return (
        <Formik
            initialValues={{ email: "", name: "Updates" }}
            validate={(values) => {
                const errors = {};
                if (!values.email) {
                    errors.email = "Required Field";
                } else if (
                    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                        values.email
                    )
                ) {
                    errors.email = "Please enter a valid mail address";
                }
                return errors;
            }}
            onSubmit={(values, actions) => {
                document.getElementById("fForm").innerHTML =
                    "<p class='thank-you-message'>Welcome on board!</p>";
                fetch("/.netlify/functions/mail", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(values),
                }).then((res) => {
                    console.log(res);
                });
            }}
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
            }) => (
                <form
                    id="fForm"
                    className={styles.contactForm}
                    onSubmit={handleSubmit}
                >
                    <div className={styles.fieldsContainer}>
                        <label>
                            <input
                                type="email"
                                name="email"
                                aria-label="Email"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.email}
                            />
                            <span className={styles.labelText}>Email</span>
                            <span className={styles.errorText}>
                                {errors.email && touched.email && errors.email}
                            </span>
                        </label>
                        <input type="hidden" name="name" value="Updates" />
                        <button type="submit" disabled={isSubmitting}>
                            <span className="d-md-none font-weight-light">
                                Submit&nbsp;
                            </span>
                            &#8674;
                        </button>
                    </div>
                </form>
            )}
        </Formik>
    );
};

export default SubscribeForm;
