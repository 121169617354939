// extracted by mini-css-extract-plugin
export var hero = "index-module--hero--3y8uL";
export var overlay = "index-module--overlay--37e_v";
export var heroPattern = "index-module--heroPattern--3uZ5L";
export var productsPattern = "index-module--productsPattern--1svTn";
export var networkIllustration = "index-module--networkIllustration--2FZQl";
export var formPattern = "index-module--formPattern--15ch1";
export var farmingPattern = "index-module--farmingPattern--2xsYH";
export var floatingNumber = "index-module--floatingNumber--1azu8";
export var floatingIcon = "index-module--floatingIcon--WfCxL";
export var stakingIcon = "index-module--stakingIcon--tCQE9";