import React from "react";
import get from "lodash/get";

import { graphql } from "gatsby";

import * as styles from "../styles/index.module.scss";

import Layout from "../components/global/layout";
import Container from "../components/global/container";
import SubscribeForm from "../components/subscribe-form/subscribe-form";
import Socials from "../components/socials/socials";

import CheckIcon from "../../static/check.svg";
import OneIcon from "../../static/one.svg";
import TwoIcon from "../../static/two.svg";
import ThreeIcon from "../../static/three.svg";
import FourIcon from "../../static/four.svg";

class RootIndex extends React.Component {
    render() {
        const meta = get(this, "props.data.meta");
        const { location } = this.props;

        const darkMode = true;

        return (
            <Layout title={meta.siteMetadata.title} location={location}>
                <article>
                    <section className={styles.hero}>
                        <div className={styles.overlay}></div>
                        <div className={styles.heroPattern}>
                            <img
                                src="../../pattern-dark.svg"
                                alt="Background Pattern"
                            />
                        </div>
                        <Container className="h-100">
                            <div className="row h-100 align-items-center justify-content-center position-relative z-index-3">
                                <div className="col-12 text-center pt-60">
                                    <div
                                        className={`mb-10 text-white font-weight-thin font-size-md`}
                                    >
                                        A BANKLESS FUTURE
                                    </div>
                                    <h1
                                        className={`text-mask-primary font-weight-bold line-height-2 font-size-xl`}
                                    >
                                        The World's First DeFi Ecosystem
                                    </h1>
                                    <Socials customStyles="max-width-300 mx-auto mt-20 mt-md-40"></Socials>
                                </div>
                            </div>
                        </Container>
                    </section>
                    <section
                        className={
                            darkMode
                                ? "background-secondary-black text-white"
                                : ""
                        }
                    >
                        <Container className="py-20 py-md-40">
                            <div className="row py-40 py-md-60">
                                <div className="col-12 col-md-6">
                                    <h2 className="mb-20 mb-md-40 font-size-lg font-weight-bold">
                                        <span className="text-mask-primary">
                                            Next-Gen
                                        </span>{" "}
                                        Decentralized Monetary Management
                                    </h2>
                                    <p className={"font-size-sm"}>
                                        Making advanced data-based interest
                                        bearing Defi investments accessible to
                                        the entire crypto community, taking our
                                        bankless future a step forward.
                                    </p>
                                    <div className="row mt-20 mt-md-40">
                                        <div className="col-12 col-md-6 position-relative mb-10 mb-md-20">
                                            <h3 className="font-size-sm font-weight-light">
                                                <CheckIcon className="mr-10"></CheckIcon>
                                                Governance DAO
                                            </h3>
                                        </div>
                                        <div className="col-12 col-md-6 position-relative mb-10 mb-md-20">
                                            <h3
                                                className={
                                                    "font-size-sm font-weight-light"
                                                }
                                            >
                                                <CheckIcon className="mr-10"></CheckIcon>
                                                Multi-Chain
                                            </h3>
                                        </div>
                                        <div className="col-12 col-md-6 position-relative mb-10 mb-md-0">
                                            <h3 className="font-size-sm font-weight-light">
                                                <CheckIcon className="mr-10"></CheckIcon>
                                                Non-Custodial
                                            </h3>
                                        </div>
                                        <div className="col-12 col-md-6 position-relative">
                                            <h3 className="font-size-sm font-weight-light">
                                                <CheckIcon className="mr-10"></CheckIcon>
                                                Fair Distribution
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <img
                                        className={styles.networkIllustration}
                                        src="../../network.svg"
                                        alt="Unifyd Ecosystem Illustration"
                                    />
                                </div>
                            </div>
                        </Container>
                    </section>
                    <section
                        className={
                            darkMode
                                ? "background-black text-white position-relative overflow-hidden"
                                : "background-light-gray position-relative overflow-hidden"
                        }
                    >
                        <div className={styles.productsPattern}>
                            <img
                                src="../../pattern-lean-dark.svg"
                                alt="Background Pattern"
                            />
                        </div>
                        <Container className="py-20 py-md-40">
                            <div className="row py-40 py-md-60">
                                <div className="col-12 mb-40 mb-md-60">
                                    <h2 className="mb-20 mb-md-40 font-size-lg font-weight-bold">
                                        A Smart Suite Of{" "}
                                        <span className="text-mask-primary">
                                            Bankless
                                        </span>{" "}
                                        Investment Products
                                    </h2>
                                </div>
                                <div className="col-12 col-md-6 position-relative mb-30">
                                    <div className="card h-100 p-20 p-md-40">
                                        <span className={styles.floatingIcon}>
                                            <OneIcon></OneIcon>
                                        </span>
                                        <p>
                                            <strong>
                                                Flash Loan Arbitrage
                                            </strong>
                                        </p>
                                        <p>
                                            Arbitrage in DeFi is trading in
                                            between DEXs with different price
                                            for an asset. Trade algorithms can
                                            take advantage of the market’s
                                            inefficiencies to buy and sell the
                                            crypto-assets at a different price
                                            points to gain financial benefits.
                                        </p>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 position-relative mb-30">
                                    <div className="card h-100 p-20 p-md-40">
                                        <span className={styles.floatingIcon}>
                                            <TwoIcon></TwoIcon>
                                        </span>
                                        <p>
                                            <strong>Flash Liquidation</strong>
                                        </p>
                                        <p>
                                            Liquidation is the process of
                                            liquidating a loan's collateral on
                                            lending platforms. Flash Liquidation
                                            is the act of buying
                                            under-collateralizaed assets at a
                                            specific discount with a Flash Loan
                                            on lending platforms.
                                        </p>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 position-relative mb-30">
                                    <div className="card h-100 p-20 p-md-40">
                                        <span className={styles.floatingIcon}>
                                            <ThreeIcon></ThreeIcon>
                                        </span>
                                        <p>
                                            <strong>Composable Trades</strong>
                                        </p>
                                        <p>
                                            Under Collateralized Loans are one
                                            of the most important building
                                            blocks of traditional finance. Unifyd's
                                            unique rating mechanism enables
                                            strong adoption and trust in
                                            decentralized lending, empowering
                                            our new financial future.
                                        </p>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 position-relative mb-30">
                                    <div className="card h-100 p-20 p-md-40">
                                        <span className={styles.floatingIcon}>
                                            <FourIcon></FourIcon>
                                        </span>
                                        <p>
                                            <strong>Vaults</strong>
                                        </p>
                                        <p>
                                            Capital pools powered by Unifyd's AI
                                            that automatically generate yield.
                                            aVaults socialize gas costs,
                                            automate yield generation,
                                            re-balancing, and automatically
                                            shift capital as the market changes
                                            to generate passive income.
                                        </p>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 position-relative mb-30 mb-md-0">
                                    <div className="card h-100 p-20 p-md-40">
                                        <span className={styles.floatingIcon}>
                                            <FourIcon></FourIcon>
                                        </span>
                                        <p>
                                            <strong>Multi-Asset Swaps</strong>
                                        </p>
                                        <p>
                                            Capital pools powered by Unifyd's AI
                                            that automatically generate yield.
                                            aVaults socialize gas costs,
                                            automate yield generation,
                                            re-balancing, and automatically
                                            shift capital as the market changes
                                            to generate passive income.
                                        </p>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 position-relative">
                                    <div className="card h-100 p-20 p-md-40">
                                        <span className={styles.floatingIcon}>
                                            <FourIcon></FourIcon>
                                        </span>
                                        <p>
                                            <strong>
                                                Dynamic Index Tokens
                                            </strong>
                                        </p>
                                        <p>
                                            Capital pools powered by Unifyd's AI
                                            that automatically generate yield.
                                            aVaults socialize gas costs,
                                            automate yield generation,
                                            re-balancing, and automatically
                                            shift capital as the market changes
                                            to generate passive income.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </Container>
                    </section>
                    <section
                        className={
                            darkMode
                                ? "background-secondary-black text-white"
                                : ""
                        }
                    >
                        <Container className="py-20 py-md-40">
                            <div className="row py-40 py-md-60">
                                <div className="col-12">
                                    <h2 className="font-size-lg font-weight-bold mb-40 mb-md-60">
                                        A New Paradigm In Protocol & Smart
                                        Contract{" "}
                                        <span className="text-mask-primary">
                                            Security
                                        </span>
                                    </h2>
                                </div>
                                <div
                                    className={
                                        "col-12 col-md-6 col-lg-3 position-relative mb-30 mb-lg-0"
                                    }
                                >
                                    <span className={styles.floatingNumber}>
                                        1
                                    </span>
                                    <p className="display-paragraph">
                                        Routine Professional <br />
                                        Security Audits
                                    </p>
                                </div>
                                <div
                                    className={
                                        "col-12 col-md-6 col-lg-3 position-relative mb-30 mb-lg-0"
                                    }
                                >
                                    <span className={styles.floatingNumber}>
                                        2
                                    </span>
                                    <p className="display-paragraph">
                                        Peer Reviews & <br />
                                        Security Audits
                                    </p>
                                </div>
                                <div
                                    className={
                                        "col-12 col-md-6 col-lg-3 position-relative mb-30 mb-md-0"
                                    }
                                >
                                    <span className={styles.floatingNumber}>
                                        3
                                    </span>
                                    <p className="display-paragraph">
                                        Community Reviews & Security Audits
                                    </p>
                                </div>
                                <div
                                    className={
                                        "col-12 col-md-6 col-lg-3 position-relative"
                                    }
                                >
                                    <span className={styles.floatingNumber}>
                                        4
                                    </span>
                                    <p className="display-paragraph">
                                        Bug Bounty <br />
                                        Programs
                                    </p>
                                </div>
                            </div>
                        </Container>
                    </section>
                    <section
                        className={
                            darkMode
                                ? "background-black text-white"
                                : "background-light-gray"
                        }
                    >
                        <Container className="py-20 py-md-40">
                            <div className="row py-40 py-md-60 m-0">
                                <div className="col-12 mb-40 mb-md-60 card overflow-hidden">
                                    <div className="row">
                                        <div className="col-12 p-20 p-md-40 p-lg-60">
                                            <div className="row flex-column-reverse flex-md-row">
                                                <div className="col-12 col-md-6 mb-20 mb-md-0 d-flex justify-content-center align-items-center">
                                                    <img
                                                        className="w-100 max-width-400"
                                                        src="../../tokens.svg"
                                                        alt="Tokens Illustration"
                                                    />
                                                </div>
                                                <div className="col-12 col-md-6 pt-20 pt-md-0 mb-40 mb-md-0 d-flex flex-direction-column justify-content-center">
                                                    <h2 className="line-height-2 mb-10 mb-md-20 font-size-lg font-weight-bold">
                                                        <span className="font-weight-light text-mask-primary font-family-source-sans-pro">
                                                            Φ
                                                        </span>
                                                        Tokens
                                                    </h2>
                                                    <p>
                                                        Unifyd interest bearing
                                                        tokens are minted upon
                                                        deposit and burned when
                                                        redeemed. The{" "}
                                                        <span className="font-weight-light font-family-source-sans-pro">
                                                            Φ
                                                        </span>
                                                        Tokens (pronounced
                                                        phiTokens) are pegged
                                                        1:1 to the value of the
                                                        underlying asset that is
                                                        deposited in Unifyd
                                                        protocol.{" "}
                                                        <span className="font-weight-light font-family-source-sans-pro">
                                                            Φ
                                                        </span>
                                                        Tokens, such as{" "}
                                                        <span className="font-weight-light font-family-source-sans-pro">
                                                            Φ
                                                        </span>
                                                        Dai, can be freely
                                                        stored, transferred, and
                                                        traded. While the
                                                        underlying asset is
                                                        loaned out to borrowers,{" "}
                                                        <span className="font-weight-light font-family-source-sans-pro">
                                                            Φ
                                                        </span>
                                                        Tokens accrue interest
                                                        in real time, directly
                                                        in your wallet!
                                                        Seriously, you can watch
                                                        your balance grow every
                                                        minute.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 border-top position-relative p-20 p-md-40 p-lg-60">
                                            <h2 className="font-weight-bold font-size-md mb-10 mb-md-20 mt-20 mt-md-0">
                                                Farming & Staking
                                            </h2>
                                            <p className="max-width-750 mb-20 mb-md-0">
                                                Farm native "UNIFY" tokens by
                                                interacting with the protocol,
                                                staking and providing liquidity
                                                to the different contracts.
                                                Farmed tokens are distributed
                                                daily based on your activity,
                                                stake amount and collected fees.
                                            </p>
                                            <img
                                                className={styles.stakingIcon}
                                                src="../../staking.svg"
                                                alt="Unifyd Token Illustration"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 card background-primary py-40 py-md-60 position-relative overflow-hidden">
                                    <div className={styles.formPattern}>
                                        <img
                                            src="../../pattern-light.svg"
                                            alt="Background Pattern"
                                        />
                                    </div>
                                    <h2 className="line-height-2 mb-40 mt-20 mt-md-40 font-size-lg font-weight-bold max-width-600 mx-auto text-center position-relative z-index-2">
                                        Stay up to date with Unifyd
                                    </h2>
                                    <div className="max-width-500 mx-auto mb-20 mb-md-40 position-relative z-index-2">
                                        <SubscribeForm />
                                    </div>
                                </div>
                            </div>
                        </Container>
                    </section>
                </article>
            </Layout>
        );
    }
}

export default RootIndex;

export const RootIndexQuery = graphql`
    query RootIndex {
        meta: site {
            siteMetadata {
                brandName
                description
                fullBrandName
                title
                siteUrl
            }
        }
    }
`;
